(function () {
    //$("#news-close").click(function () {
    //    $('#news-feed').fadeToggle("fast");
    //});
    jQuery('.news-item a').click(function(e){
        jQuery(this).closest('.fp-tableCell').addClass('reverse');
        
    });
    jQuery('.close-news-button').click(function(e){
        jQuery(this).closest('.fp-tableCell').removeClass('reverse');

    });
    
})();