(function () {
  
  let map;
  
  window.initMap = function () {
    const myLatLng = {lat: 52.1534642991573, lng: -0.7016242625000112};
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 10,
      center: myLatLng,
    });
    const image = {url: "/images/pin.svg", scaledSize: new google.maps.Size(60, 60)};
    new google.maps.Marker({
      position: myLatLng,
      map,
      icon: image,
    });
  };
  
})();
