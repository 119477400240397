require('./modules/lightbox');
require('bootstrap');
require('jquery/src/jquery');



$('body').on('gsap.loaded', function () { 
    require('./modules/layer-toggle');
    require('./pages/Sectors');
    require('./pages/About');
    require('./pages/Contact');
    require('./pages/News');
    require('./pages/Services');
    require('./pages/Projects');
    require('./pages/Project');
    require('./pages/Relationships');
    require('./modules/nav');
});

