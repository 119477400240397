(function () {
   $('[data-target="#main-nav"]').on('click', function() {      	
      $(this).toggleClass('is-active');
   });

   $('[data-target="#sub-nav"]').on('click', function() {      	
      $(this).toggleClass('is-active');
   });

   $('[data-target="#search-modal"]').on('click', function() {      	
      $('#search-input').focus();
   });

   
})();