(function () {
    var $ = jQuery = require('jquery');
    var Isotope = require('isotope-layout');
    require('isotope-packery');
    function init() {
        // jQuery('.isotope').on('reload', function () {
        //     this.iso.layout();
        // });
        // var elements = jQuery('.isotope').each(function (index, elem) {
        //     if (elem !== null) {
        //         elem.iso = new Isotope(elem, {
        //             // options
        //             itemSelector: '.cell',
        //             layoutMode: 'packery'
        //         });
        //     }
        // });
    }
    init();
    $('#fullpage').on('dca.content.visible',init);
    
})();