(function () {
    $('body').on('click',".team-img.overlay-activator",overlayActivator);
    $('body').on('click',"#info-overlay-close",closeOverlay);
    $('body').on('click',"#info-overlay-bg",closeOverlayBg);
    
    function overlayActivator( e) {
        var teamId = $(this).data("member");
        var uri = $(this).data("slug");
        var showPopup = $(this).data('popup');
        if(showPopup !== 0) {
            $('#info-overlay .row').empty();
            $('#info-overlay').fadeIn('slow');
            $('#info-overlay .row').load(uri + ' .row:first > *');
            $('#info-overlay-bg').fadeIn('slow');
            $('#info-overlay-close').fadeIn('slow');
        }
    }

    function closeOverlay() {
        $(this).fadeOut('slow');
        $('#info-overlay').fadeOut('slow');
        $('#info-overlay-bg').fadeOut('slow');
    }

    function closeOverlayBg() {
        $(this).fadeOut('slow');
        $('#info-overlay').fadeOut('slow');
        $('#info-overlay-close').fadeOut('slow');
    }
})();